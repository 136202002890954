<template>
  <base-view>
    <view-section class="section1 pt-56 text-center text-white">
      <div class="flex flex-col">
        <p class="my-6 text-8xl font-bold">404</p>
        <p class="m-0">
          Sorry, but the page you requested was not found
        </p>
        <div class="flex flex-col justify-center sm:flex-row">
          <Button reg tooltipHover="Test" to="/" class="m-4">Go Home</Button>
          <Button reg to="/contact" class="m-4">Contact Us</Button>
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import Button from "@/components/ui/Button.vue";

export default {
  components: {
    BaseView,
    ViewSection,
    Button: Button
  }
};
</script>

<style scoped>
.section1 {
  height: calc(100vh - 5rem);
  background-image: url("~@/assets/bg-404.jpg");
}
</style>
